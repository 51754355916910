import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

const useSearch = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const search = searchParams.get('search') || '';

    const handleSearch = useDebouncedCallback((value) => {
        if (value) {
            setSearchParams({ search: value });
        } else {
            setSearchParams((searchParams) => {
                if (searchParams.has('search')) searchParams.delete('search');
                return searchParams;
            });
        }
    }, 300);

    return { search, handleSearch };
};

export { useSearch };
