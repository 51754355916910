import { Button } from 'components/Button';
import { useForm } from 'react-hook-form';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { Tooltip } from 'react-tooltip';

import settingsStyles from 'styles/Settings.module.css';
import styles from '../styles.module.css';

const LisaSettingsCard = ({ item, onSubmit }) => {
    const { register, handleSubmit } = useForm({
        defaultValues: {
            ...(item.lisa_settings ? item.lisa_settings : {}),
            priority: item.lisa_settings
                ? String(item.lisa_settings.priority)
                : 'false',
        },
    });

    return (
        <div>
            <h2 className={settingsStyles.heading}>Customer Information</h2>
            <form
                className={settingsStyles.form}
                onSubmit={handleSubmit(onSubmit)}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                    }}
                >
                    <div
                        style={{
                            color: 'var(--secondary-darkest)',
                            fontSize: 'var(--text-xs)',
                            fontWeight: 600,
                        }}
                    >
                        Priority
                    </div>
                    <div style={{ display: 'flex', gap: '12px' }}>
                        <div className={styles.radioItem}>
                            <input
                                id='priorityYes'
                                type='radio'
                                value='true'
                                {...register('priority')}
                            />
                            <label htmlFor='priorityYes'>Yes</label>

                            <HiOutlineInformationCircle
                                size={16}
                                data-tooltip-content='Priority addresses are pinned to the top of the LISA view'
                                data-tooltip-id='priority-tooltip'
                            />

                            <Tooltip
                                id='priority-tooltip'
                                style={{ maxWidth: '80%' }}
                            />
                        </div>

                        <div className={styles.radioItem}>
                            <input
                                id='priorityNo'
                                type='radio'
                                value='false'
                                {...register('priority')}
                            />
                            <label htmlFor='priorityNo'>No</label>
                        </div>
                    </div>
                </div>

                <Button uitype='cta' color='primary-dark' size='lg' fullwidth>
                    Save
                </Button>
            </form>
        </div>
    );
};

export { LisaSettingsCard };
