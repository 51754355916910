import { useSearchParams } from 'react-router-dom';

const useCompanyFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const company = searchParams.get('company') || '';

    const setCompany = (company) => {
        setSearchParams({ company });
    };

    const clearCompany = () => {
        setSearchParams((searchParams) => {
            if (searchParams.has('company')) searchParams.delete('company');
            return searchParams;
        });
    };

    return { company, setCompany, clearCompany };
};

export { useCompanyFilter };
