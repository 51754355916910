import { useForm } from 'react-hook-form';
import { Input } from 'components/Form';
import styles from './styles.module.css';
import { Button } from 'components/Button';
import { FaCheck, FaPencil, FaPlus, FaTrashCan, FaX } from 'react-icons/fa6';
import { useUser } from 'components/Accounts';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Modal } from 'components/Modal';
import { AutoQuoteSettingsCard } from '../Cards/AutoQuoteSettingsCard';
import { TmsOrdersSettingsCard } from '../Cards/TmsOrdersSettingsCard';
import { useInboxSenderAllowList } from 'hooks/useInboxSenderAllowlist';
import { LisaSettingsCard } from '../Cards/LisaSettingsCard';
import toast from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';

const EMAIL_DOMAIN_REGEX =
    /^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)$/;

const EditableField = ({ item, label, accessor, selectedCompanyid }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { updateItem } = useInboxSenderAllowList(selectedCompanyid);

    const getValue = () => {
        switch (accessor) {
            case 'inbox':
                return item.inbox_sender.split('::').at(0) || '*';
            case 'sender':
                return item.inbox_sender.split('::').at(1) || '*';
            default:
                return item[accessor];
        }
    };

    const [cellValue, setCellValue] = useState(getValue());

    const {
        register,
        watch,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            [`${item.inbox_sender}:${accessor}`]: getValue(),
        },
    });

    const validateSender = () => {
        if (
            accessor === 'sender' &&
            !EMAIL_DOMAIN_REGEX.test(cellValue) &&
            !['*', ''].includes(cellValue?.trim())
        ) {
            reset();
            toast.error('Invalid email or domain', {
                id: `${item.inbox_sender}:${accessor}`,
            });
            return false;
        }
        return true;
    };

    const wrapperRef = useRef(null);
    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            const handleClickOutside = (e) => {
                if (ref.current && !ref.current.contains(e.target)) {
                    setIsEditing(false);
                    setIsSubmitting(true); // trigger form submission via useEffect
                }
            };
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    };
    useOutsideAlerter(wrapperRef);

    useEffect(() => {
        if (!isEditing && isSubmitting) {
            if (!validateSender()) return;
            updateItem(item.company_id, item.inbox_sender, {
                [accessor]: ['inbox', 'sender'].includes(accessor)
                    ? cellValue || '*'
                    : cellValue,
            });
            setIsSubmitting(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing, isSubmitting]);

    if (!isEditing) {
        return (
            <div
                className={classNames(styles.item, styles[accessor])}
                style={{ cursor: 'pointer' }}
                onClick={() => setIsEditing(true)}
            >
                <label>{label}</label>
                <span
                    data-tooltip-content={getValue()}
                    data-tooltip-id='item-value-tooltip'
                >
                    {getValue()}
                </span>
                <Tooltip
                    id='item-value-tooltip'
                    style={{ wordBreak: 'break-all' }}
                    opacity={0.2}
                />
            </div>
        );
    }

    return (
        <div
            className={classNames(
                styles.item,
                styles.editableCell,
                styles[accessor]
            )}
            ref={wrapperRef}
        >
            <Input
                label={`${item.inbox_sender}:${accessor}`}
                placeholder={label}
                watch={watch}
                register={register}
                value={cellValue}
                errors={errors}
                size='sm'
                className={styles.editableCellInput}
                disabled={isEditing ? false : true}
                onChange={(e) => {
                    setCellValue(e.target.value);
                }}
                onKeyDown={async (e) => {
                    if (e.key === 'Enter') {
                        setIsEditing(false);
                        if (!validateSender()) return;
                        updateItem(item.company_id, item.inbox_sender, {
                            [accessor]: cellValue,
                        });
                    }
                }}
            />
        </div>
    );
};

const AllowlistItem = ({
    item,
    showLisa,
    showAutoQuote,
    showTmsOrders,
    showReplier,
    showWinLoss,
    selectedCompanyid,
}) => {
    const { user } = useUser();
    const { createItem, updateItem, deleteItem } =
        useInboxSenderAllowList(selectedCompanyid);
    const [deleting, setDeleting] = useState(false);

    const {
        register,
        watch,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: item,
    });

    const onSubmit = async (data) => {
        // Check if fields are empty
        if (!data.inbox && !data.sender_address) {
            setError('inbox', {
                message: 'Required',
            });
            setError('sender_address', {
                message: 'Required',
            });
            return;
        }
        // Check if source_recipient is a valid email OR domain.
        // Essentially just check if it has a .<something> at the end or @.
        if (
            !EMAIL_DOMAIN_REGEX.test(data.sender_address) &&
            !['*', ''].includes(data.sender_address?.trim())
        ) {
            setError('sender_address', {
                message: 'Invalid email or domain',
            });
            return;
        }
        createItem(
            user.company_id,
            data.sender_name,
            data.inbox,
            data.sender_address,
            () =>
                reset({
                    sender_name: '',
                    inbox: '',
                    sender_address: '',
                })
        );
    };

    const [showLisaSettingsCard, setShowLisaSettingsCard] = useState(false);
    const [showAutoQuoteSettingsCard, setShowAutoQuoteSettingsCard] =
        useState(false);
    const [showTmsOrdersSettingsCard, setShowTmsOrdersSettingsCard] =
        useState(false);

    if (item) {
        return (
            <>
                <div
                    className={styles.allowlistItem}
                    id={item.source_recipient}
                >
                    <EditableField
                        item={item}
                        label='Name'
                        accessor='sender_name'
                        selectedCompanyid={selectedCompanyid}
                    />
                    <EditableField
                        item={item}
                        label='Inbox ID'
                        accessor='inbox'
                        selectedCompanyid={selectedCompanyid}
                    />
                    <EditableField
                        item={item}
                        label='Sender Address'
                        accessor='sender'
                        selectedCompanyid={selectedCompanyid}
                    />

                    {showLisa && (
                        <div
                            className={classNames(
                                styles.item,
                                styles.shrinkWidth
                            )}
                        >
                            <label>LISA</label>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                }}
                            >
                                <select
                                    className={styles.autoSendSelect}
                                    onChange={async (event) => {
                                        await updateItem(
                                            item.company_id,
                                            item.inbox_sender,
                                            {
                                                enabled_features: {
                                                    ...item.enabled_features,
                                                    lisa:
                                                        event.target.value ===
                                                        'true',
                                                },
                                            }
                                        );
                                    }}
                                    value={
                                        item.enabled_features?.['lisa'] ??
                                        'false'
                                    }
                                >
                                    <option value='false'>Disable</option>
                                    <option value='true'>Enable</option>
                                </select>

                                <Button
                                    uitype='icon'
                                    color='primary-dark'
                                    size='sm'
                                    style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                    }}
                                    type='button'
                                    onClick={() =>
                                        setShowLisaSettingsCard(true)
                                    }
                                >
                                    <FaPencil size={12} />
                                </Button>
                            </div>
                        </div>
                    )}

                    {showAutoQuote && (
                        <div className={styles.item}>
                            <label>Auto-Quote</label>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                }}
                            >
                                <select
                                    className={styles.autoSendSelect}
                                    onChange={async (event) => {
                                        await updateItem(
                                            item.company_id,
                                            item.inbox_sender,
                                            {
                                                enabled_features: {
                                                    ...item.enabled_features,
                                                    'auto-quote':
                                                        event.target.value,
                                                },
                                            }
                                        );
                                    }}
                                    value={
                                        item.enabled_features?.['auto-quote'] ??
                                        'disabled'
                                    }
                                >
                                    <option value='disabled'>
                                        Don't quote
                                    </option>
                                    <option value='draft'>
                                        Create draft email
                                    </option>
                                    <option value='send'>
                                        Auto-send email
                                    </option>
                                </select>

                                <Button
                                    uitype='icon'
                                    color='primary-dark'
                                    size='sm'
                                    style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                    }}
                                    type='button'
                                    onClick={() =>
                                        setShowAutoQuoteSettingsCard(true)
                                    }
                                >
                                    <FaPencil size={12} />
                                </Button>
                            </div>
                        </div>
                    )}

                    {showTmsOrders && (
                        <div className={styles.item}>
                            <label>Send Orders to TMS</label>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                }}
                            >
                                <select
                                    className={styles.autoSendSelect}
                                    onChange={async (event) => {
                                        await updateItem(
                                            item.company_id,
                                            item.inbox_sender,
                                            {
                                                enabled_features: {
                                                    ...item.enabled_features,
                                                    'order-entry':
                                                        event.target.value,
                                                },
                                            }
                                        );
                                    }}
                                    value={
                                        item.enabled_features?.[
                                            'order-entry'
                                        ] ?? 'disabled'
                                    }
                                >
                                    <option value='disabled'>
                                        Don't create order
                                    </option>
                                    <option value='draft'>
                                        Create draft order
                                    </option>
                                    <option value='send'>
                                        Auto-send order
                                    </option>
                                </select>

                                <Button
                                    uitype='icon'
                                    color='primary-dark'
                                    size='sm'
                                    style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                    }}
                                    type='button'
                                    onClick={() =>
                                        setShowTmsOrdersSettingsCard(true)
                                    }
                                >
                                    <FaPencil size={12} />
                                </Button>
                            </div>
                        </div>
                    )}

                    {showReplier && (
                        <div
                            className={classNames(
                                styles.item,
                                styles.shrinkWidth
                            )}
                        >
                            <label>Auto-Reply</label>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                }}
                            >
                                <select
                                    className={styles.autoSendSelect}
                                    onChange={async (event) => {
                                        await updateItem(
                                            item.company_id,
                                            item.inbox_sender,
                                            {
                                                enabled_features: {
                                                    ...item.enabled_features,
                                                    replier:
                                                        event.target.value ===
                                                        'true',
                                                },
                                            }
                                        );
                                    }}
                                    value={
                                        item.enabled_features?.['replier'] ??
                                        'false'
                                    }
                                >
                                    <option value='false'>Disable</option>
                                    <option value='true'>Enable</option>
                                </select>
                            </div>
                        </div>
                    )}

                    {showWinLoss && (
                        <div
                            className={classNames(
                                styles.item,
                                styles.shrinkWidth
                            )}
                        >
                            <label>Win-Loss</label>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                }}
                            >
                                <select
                                    className={styles.autoSendSelect}
                                    onChange={async (event) => {
                                        await updateItem(
                                            item.company_id,
                                            item.inbox_sender,
                                            {
                                                enabled_features: {
                                                    ...item.enabled_features,
                                                    'win-loss':
                                                        event.target.value ===
                                                        'true',
                                                },
                                            }
                                        );
                                    }}
                                    value={
                                        item.enabled_features?.['win-loss'] ??
                                        'false'
                                    }
                                >
                                    <option value='false'>Disable</option>
                                    <option value='true'>Enable</option>
                                </select>
                            </div>
                        </div>
                    )}

                    <div className={styles.item}>
                        <label>Created</label>
                        <span>
                            {item.created_at
                                ? new Date(
                                      item.created_at * 1000
                                  ).toLocaleTimeString([], {
                                      year: 'numeric',
                                      month: 'numeric',
                                      day: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                  })
                                : '—'}
                        </span>
                    </div>
                    <div className={styles.item}>
                        <label>Updated</label>
                        <span>
                            {item.updated_at
                                ? new Date(
                                      item.updated_at * 1000
                                  ).toLocaleTimeString([], {
                                      year: 'numeric',
                                      month: 'numeric',
                                      day: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                  })
                                : '—'}
                        </span>
                    </div>
                    {deleting ? (
                        <div style={{ display: 'flex', gap: '0.5rem' }}>
                            <Button
                                uitype='cta'
                                type='button'
                                size='md'
                                color='red'
                                onClick={() => deleteItem(item)}
                            >
                                <FaCheck />
                            </Button>
                            <Button
                                uitype='ghost'
                                size='sm'
                                type='button'
                                onClick={() => setDeleting(false)}
                            >
                                <FaX />
                            </Button>
                        </div>
                    ) : (
                        <Button
                            uitype='ghost'
                            color='red'
                            size='sm'
                            type='button'
                            onClick={() => setDeleting(true)}
                        >
                            <FaTrashCan />
                        </Button>
                    )}
                </div>

                {showLisaSettingsCard && (
                    <Modal
                        wide
                        open={showLisaSettingsCard}
                        handler={setShowLisaSettingsCard}
                    >
                        <LisaSettingsCard
                            item={item}
                            onSubmit={(data) => {
                                updateItem(item.company_id, item.inbox_sender, {
                                    lisa_settings: {
                                        ...data,
                                        priority: data.priority === 'true',
                                    },
                                });
                                setShowLisaSettingsCard(false);
                            }}
                        />
                    </Modal>
                )}

                {showAutoQuoteSettingsCard && (
                    <Modal
                        wide
                        open={showAutoQuoteSettingsCard}
                        handler={setShowAutoQuoteSettingsCard}
                    >
                        <AutoQuoteSettingsCard
                            item={item}
                            onSubmit={(data) => {
                                updateItem(item.company_id, item.inbox_sender, {
                                    auto_quote_settings: data,
                                });
                                setShowAutoQuoteSettingsCard(false);
                            }}
                        />
                    </Modal>
                )}

                {showTmsOrdersSettingsCard && (
                    <Modal
                        open={showTmsOrdersSettingsCard}
                        handler={setShowTmsOrdersSettingsCard}
                    >
                        <TmsOrdersSettingsCard
                            item={item}
                            onSubmit={(data) => {
                                updateItem(item.company_id, item.inbox_sender, {
                                    order_entry_settings: data,
                                });
                                setShowTmsOrdersSettingsCard(false);
                            }}
                        />
                    </Modal>
                )}
            </>
        );
    }

    return (
        <div className={classNames(styles.form, styles.allowlistItem)}>
            <Input
                label='sender_name'
                placeholder='Name'
                watch={watch}
                register={register}
                name='sender_name'
                errors={errors}
                size='sm'
                disabled={!item ? false : true}
            />
            <Input
                label='inbox'
                placeholder='Inbox ID'
                watch={watch}
                register={register}
                name='inbox'
                errors={errors}
                size='sm'
                disabled={!item ? false : true}
            />
            <Input
                label='sender_address'
                placeholder='Sender Address'
                watch={watch}
                register={register}
                name='sender_address'
                errors={errors}
                size='sm'
                disabled={!item ? false : true}
            />
            <Button
                uitype='cta'
                color='primary-dark'
                type='button'
                size='sm'
                onClick={() => {
                    clearErrors();
                    handleSubmit(onSubmit)();
                }}
            >
                <FaPlus /> Save
            </Button>
        </div>
    );
};

export { AllowlistItem };
