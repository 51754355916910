import { ProtectedPage } from 'components/Page';
import {
    AllowlistTable,
    InboxSenderAllowlistTable,
} from 'components/Allowlist';
import { useUser } from 'components/Accounts';
import { NEW_ALLOWLIST_MIGRATION } from 'hooks/useInboxSenderAllowlist';

/**
 * @function Allowlist
 * @description
 * Allows users to whitelist certain addresses in order
 * for LISA to filter out unwanted messages.
 */
const Allowlist = () => {
    const { user } = useUser();

    return (
        <ProtectedPage
            title='Allowlist'
            wide
            restrictions={['allowlist', 'login-required']}
        >
            {NEW_ALLOWLIST_MIGRATION?.includes(user?.company) ? (
                <InboxSenderAllowlistTable />
            ) : (
                <AllowlistTable />
            )}
        </ProtectedPage>
    );
};

export default Allowlist;
