import { useSearchParams } from 'react-router-dom';

const useSort = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const sort = searchParams.get('sort') || '';

    const setSort = (field, order) => {
        setSearchParams({ sort: `${field}:${order}` });
    };

    const clearSort = () => {
        setSearchParams((searchParams) => {
            if (searchParams.has('sort')) searchParams.delete('sort');
            return searchParams;
        });
    };

    return { sort, setSort, clearSort };
};

export { useSort };
