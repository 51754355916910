import { FaMagnifyingGlass } from 'react-icons/fa6';
import styles from 'styles/Allowlist.module.css';
import { AllowlistItem } from './AllowlistItem';
import { Button } from 'components/Button';
import toast from 'react-hot-toast';
import { useGetTmsOrdersInboxes } from 'hooks/useGetTmsOrdersInboxes';
import { useInboxSenderAllowList } from 'hooks/useInboxSenderAllowlist';
import { useSearch } from 'hooks/useSearch';
import { useSort } from 'hooks/useSort';
import { useUser } from 'components/Accounts';
import { useCompanies } from 'providers/Companies';
import { isValidAdmin } from 'helpers/user';
import { DropdownButton } from 'components/Revenue360';
import { useCompanyFilter } from 'hooks/useCompanyFilter';

const InboxSenderAllowlistTable = () => {
    const { user } = useUser();
    const { search, handleSearch } = useSearch();
    const { sort, setSort } = useSort();

    const { companies } = useCompanies();
    const companyList = companies.reduce((acc, curr) => {
        acc[curr.company] = curr.company_id;
        return acc;
    }, {});

    const { company, setCompany } = useCompanyFilter();

    const [field, order] = sort ? sort.split(':') : [];

    const { data, isValidating, error, clearFilters, mutate } =
        useInboxSenderAllowList(companyList[company]);

    const { has_tms_orders_inbox } = useGetTmsOrdersInboxes();

    return (
        <>
            {isValidAdmin(user) && Object.keys(companyList).length > 0 && (
                // allow admin to view allowlists from different companies
                <DropdownButton
                    value={company || user?.company}
                    setValue={setCompany}
                    options={Object.keys(companyList)}
                    width='min-content'
                />
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                }}
            >
                <div className={styles.card} style={{ gap: 0 }}>
                    <h2>Add a new recipient</h2>
                    <AllowlistItem selectedCompanyid={companyList[company]} />
                </div>

                <div className={styles.card}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <h2 style={{ margin: 0 }}>Current allowlist</h2>
                        {isValidating && <div>Loading...</div>}
                    </div>
                    <div className={styles.header}>
                        <div className={styles.searchbar}>
                            <FaMagnifyingGlass className={styles.icon} />
                            <input
                                type='text'
                                defaultValue={search}
                                onChange={(e) => handleSearch(e.target.value)}
                                className={styles.search}
                                placeholder='Search by name or address...'
                            />
                        </div>
                        <div className={styles.sort}>
                            {(field || search) && (
                                <Button
                                    onClick={clearFilters}
                                    size='sm'
                                    type='button'
                                    uitype='ghost'
                                    color='red'
                                >
                                    Clear filters
                                </Button>
                            )}
                            <Button
                                onClick={() => {
                                    if (field === 'sender_name') {
                                        setSort(
                                            'sender_name',
                                            order === 'asc' ? 'desc' : 'asc'
                                        );
                                    } else {
                                        setSort('sender_name', 'asc');
                                    }
                                }}
                                size='sm'
                                type='button'
                                uitype='ghost'
                                color={
                                    field === 'sender_name'
                                        ? 'primary'
                                        : 'secondary-dark'
                                }
                            >
                                {field === 'sender_name'
                                    ? order === 'asc'
                                        ? 'Sort by name (asc)'
                                        : 'Sort by name (desc)'
                                    : 'Sort by name'}
                            </Button>
                            <Button
                                onClick={() => {
                                    if (field === 'inbox') {
                                        setSort(
                                            'inbox',
                                            order === 'asc' ? 'desc' : 'asc'
                                        );
                                    } else {
                                        setSort('inbox', 'asc');
                                    }
                                }}
                                size='sm'
                                type='button'
                                uitype='ghost'
                                color={
                                    field === 'inbox'
                                        ? 'primary'
                                        : 'secondary-dark'
                                }
                            >
                                {field === 'inbox'
                                    ? order === 'asc'
                                        ? 'Sort by inbox (asc)'
                                        : 'Sort by inbox (desc)'
                                    : 'Sort by inbox'}
                            </Button>
                            <Button
                                onClick={() => {
                                    if (field === 'sender') {
                                        setSort(
                                            'sender',
                                            order === 'asc' ? 'desc' : 'asc'
                                        );
                                    } else {
                                        setSort('sender', 'asc');
                                    }
                                }}
                                size='sm'
                                type='button'
                                uitype='ghost'
                                color={
                                    field === 'sender'
                                        ? 'primary'
                                        : 'secondary-dark'
                                }
                            >
                                {field === 'sender'
                                    ? order === 'asc'
                                        ? 'Sort by sender (asc)'
                                        : 'Sort by sender (desc)'
                                    : 'Sort by sender'}
                            </Button>
                            <Button
                                onClick={() => {
                                    if (field === 'created_at') {
                                        setSort(
                                            'created_at',
                                            order === 'asc' ? 'desc' : 'asc'
                                        );
                                    } else {
                                        setSort('created_at', 'asc');
                                    }
                                }}
                                size='sm'
                                type='button'
                                uitype='ghost'
                                color={
                                    field === 'created_at'
                                        ? 'primary'
                                        : 'secondary-dark'
                                }
                            >
                                {field === 'created_at'
                                    ? order === 'asc'
                                        ? 'Sort by created (asc)'
                                        : 'Sort by created (desc)'
                                    : 'Sort by created'}
                            </Button>
                            <Button
                                onClick={() => {
                                    if (field === 'updated_at') {
                                        setSort(
                                            'updated_at',
                                            order === 'asc' ? 'desc' : 'asc'
                                        );
                                    } else {
                                        setSort('updated_at', 'asc');
                                    }
                                }}
                                size='sm'
                                type='button'
                                uitype='ghost'
                                color={
                                    field === 'updated_at'
                                        ? 'primary'
                                        : 'secondary-dark'
                                }
                            >
                                {field === 'updated_at'
                                    ? order === 'asc'
                                        ? 'Sort by updated (asc)'
                                        : 'Sort by updated (desc)'
                                    : 'Sort by updated'}
                            </Button>
                        </div>
                    </div>

                    {!isValidating && data && data.length === 0 && (
                        <p>No addresses in the allowlist.</p>
                    )}
                    {error && (
                        <p className={styles.error}>
                            ⚠ There was an error loading the allowlist.
                            {
                                // We don't know the shape of the error object
                                // so we just print it out.
                                ' ' + error.toString()
                            }
                            <Button
                                onClick={() => {
                                    toast.promise(mutate(), {
                                        loading: 'Refreshing...',
                                        success: 'Refreshed allowlist',
                                        error: 'Error refreshing allowlist',
                                    });
                                }}
                                size='sm'
                                type='button'
                                uitype='ghost'
                            >
                                Retry
                            </Button>
                        </p>
                    )}

                    <div className={styles.allowlistItems}>
                        {Array.isArray(data) &&
                            data.length > 0 &&
                            data.map((item) => (
                                <AllowlistItem
                                    key={item.inbox_sender}
                                    item={item}
                                    showLisa={true} // TODO: check for DDB inboxes
                                    showAutoQuote={true} // TODO: determine by company access to auto-quote
                                    showTmsOrders={has_tms_orders_inbox} // TODO: determine by company access to order-entry
                                    showReplier={user?.company === 'Everest'}
                                    showWinLoss={user?.company === 'Everest'}
                                    selectedCompanyid={companyList[company]}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export { InboxSenderAllowlistTable };
