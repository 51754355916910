import { useUser } from 'components/Accounts';
import fetcher from 'lib/fetcher';
import { useSearchParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import { NEW_ALLOWLIST_MIGRATION } from './useInboxSenderAllowlist';

/**
 * @function useGetAllowList
 * @description Fetches the list of whitelisted addresses
 * from the server filtered by the company ID.
 */

const useGetAllowList = () => {
    const { user, token } = useUser();
    const { mutate } = useSWRConfig();
    // Get search param from url
    const [searchParams] = useSearchParams();

    const search = searchParams.get('search');
    const sort = searchParams.get('sort');
    const [field, order] = sort ? sort.split(':') : [];

    const key =
        user && !NEW_ALLOWLIST_MIGRATION.includes(user.company)
            ? [
                  process.env.REACT_APP_SMARTBID_SERVER_BASE_URL +
                      `/api/automail/vertex/allowlist?company_id=${
                          user.company_id
                      }&search=${search || ''}${
                          field && order ? `&sort=${field}:${order}` : ''
                      }`,
                  { 'x-access-token': token },
              ]
            : null;

    const { data, isValidating, error } = useSWR(key, fetcher, {
        onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
            // never retry on 404.
            if (error.status === 404) return;
            // only retry up to 10 times.
            if (retryCount >= 10) return;
            // retry after 1.5 seconds.
            setTimeout(() => revalidate({ retryCount }), 1500);
        },
    });

    // In the future, it would be a good idea to
    // either convert to TypeScript or some how predict
    // the shape of the data from the server.
    const whitelist = data?._results ? data._results : [];

    return {
        allowList: whitelist,
        isValidating,
        error: error || data?.message, // check for message when API throws an error
        mutate,
        key,
    };
};

export { useGetAllowList };
