import { useUser } from 'components/Accounts';
import fetcher from 'lib/fetcher';
import useSWR, { useSWRConfig } from 'swr';
import { useSearch } from './useSearch';
import { useSort } from './useSort';
import toast from 'react-hot-toast';
import { smartbidServer } from 'lib/api';
import { isValidAdmin } from 'helpers/user';

const NEW_ALLOWLIST_MIGRATION = ['Everest'];

const useInboxSenderAllowList = (selectedCompanyId) => {
    const { user, token } = useUser();
    const { mutate } = useSWRConfig();

    const { search, handleSearch } = useSearch();
    const { sort, clearSort } = useSort();

    const [field, order] = sort ? sort.split(':') : [];

    const getCompanyId = (defaultCompanyId = user.company_id) => {
        // allow selected company ID override if user is admin
        if (isValidAdmin(user) && selectedCompanyId) {
            return selectedCompanyId;
        }
        return defaultCompanyId;
    };

    const SWR_KEY =
        user && NEW_ALLOWLIST_MIGRATION.includes(user.company)
            ? [
                  process.env.REACT_APP_SMARTBID_SERVER_BASE_URL +
                      `/api/allowlist?company_id=${getCompanyId()}&search=${
                          search || ''
                      }${field && order ? `&sort=${field}:${order}` : ''}`,
                  { 'x-access-token': token },
              ]
            : null;

    const { data, isValidating, error } = useSWR(SWR_KEY, fetcher, {
        onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
            // never retry on 404.
            if (error.status === 404) return;
            // only retry up to 10 times.
            if (retryCount >= 10) return;
            // retry after 1.5 seconds.
            setTimeout(() => revalidate({ retryCount }), 1500);
        },
    });

    /**
     * @function clearFilters
     * @description
     * Clear all query params.
     */
    const clearFilters = () => {
        handleSearch();
        clearSort();
        mutate(SWR_KEY);
    };

    /**
     * @function createItem
     * @description
     * Create a new allowlist item.
     */
    const createItem = async (
        company_id,
        sender_name,
        inbox,
        sender,
        callback
    ) => {
        try {
            if (!getCompanyId(company_id)) {
                throw new Error('Company ID not found');
            }

            await smartbidServer.post(`/api/allowlist`, {
                company_id: getCompanyId(company_id),
                sender_name: sender_name || '',
                inbox: inbox || '*',
                sender: sender || '*',
            });
            toast.success('Created allowlist item successfully', {
                id: 'toast-allowlist',
            });
            mutate(SWR_KEY);
            callback && callback();
        } catch (err) {
            console.error(err);
            toast.error('Error creating allowlist item', {
                id: 'toast-allowlist',
            });
        }
    };

    /**
     * @function updateItem
     * @description
     * Update allowlist item.
     */
    const updateItem = async (company_id, inbox_sender, data) => {
        try {
            if (!getCompanyId(company_id)) {
                throw new Error('Company ID not found');
            }

            await smartbidServer.patch(
                `/api/allowlist/${getCompanyId(company_id)}/${inbox_sender}`,
                data
            );
            toast.success('Updated allowlist item successfully', {
                id: 'toast-allowlist',
            });
            mutate(SWR_KEY);
        } catch (err) {
            console.error(err);
            toast.error('Error updating allowlist item', {
                id: 'toast-allowlist',
            });
        }
    };

    /**
     * @function deleteItem
     * @description
     * Delete allowlist item.
     */
    const deleteItem = async (item) => {
        if (!item) return;

        try {
            if (!item.company_id) {
                throw new Error('Company ID not found');
            }

            await smartbidServer.delete(
                `/api/allowlist/${item.company_id}/${item.inbox_sender}`
            );
            toast.success('Deleted allowlist item successfully', {
                id: 'toast-allowlist',
            });
            mutate(SWR_KEY);
        } catch (err) {
            console.error(err);
            toast.error('Error deleting allowlist item', {
                id: 'toast-allowlist',
            });
        }
    };

    return {
        data,
        isValidating,
        error,
        clearFilters,
        mutate,
        createItem,
        updateItem,
        deleteItem,
    };
};

export { NEW_ALLOWLIST_MIGRATION, useInboxSenderAllowList };
